/**
 * @module useProfileStore
 * @description This module describes all methods to read and update user profile
 * @see {@link https://users.beauty.musqogee.com/api/documentation}
 */

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useNotificationsStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import { getRandomInt } from '@/utils/random.js'
import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'
import i18n from '@/i18n.js'

const { t } = i18n.global

export const useProfileStore = defineStore('profile', () => {
  /**
   * @constant userInfo [mutable]
   * @type {Object}
   * @description Base user information
   * @param {String} name - first name
   * @param {String} lname - fast name
   * @param {String} email - email
   * @param {String} avatar - user avatar URL link (from Cabinet)
   * @param {String} photo - user photo URL link (from Quiz Scan result)
   * @param {String} role - currently might be only 'user'
   * @param {String|null} profileId - unique user ID from Quiz
   * @param {String|null} publicId - unique user ID from Cabinet
   * @param {String|null} address - address
   * @param {String|null} city - city
   * @param {String|null} company - company
   * @param {String|null} country - country
   * @param {String|null} phone - phone
   * @param {String|null} state - state
   * @param {String|null} zipcode - zipcode
   */
  const userInfo = ref({
    name: '',
    lname: '',
    email: '',
    avatar: '',
    photo: '',
    role: '',
    profileId: null,
    publicId: null,
    address: null,
    city: null,
    company: null,
    country: null,
    phone: null,
    state: null,
    zipcode: null,
  })

  /**
   * @constant profilePending
   * @see {@link https://youtrack.musqogee.com/issue/LY-433}
   */
  const profilePending = ref(true)
  const productsPending = ref(true)

  /**
   * @constant userInitials [computable]
   * @returns User initials based on first and last name
   * @example SN | NI | TP | DP | JD
   */
  const userInitials = computed(() => {
    return userInfo.value.name.charAt(0) + userInfo.value.lname.charAt(0)
  })

  /**
   * @constant userAddress [computable]
   * @returns Fields needed to show in personal profile
   */
  const userAddress = computed(() => {
    return [
      {
        title: t('profile.address.country'),
        value: userInfo.value.country,
      },
      {
        title: t('profile.address.state'),
        value: userInfo.value.state,
      },
      {
        title: t('profile.address.city'),
        value: userInfo.value.city,
      },
      {
        title: t('profile.address.street'),
        value: userInfo.value.address,
      },
      {
        title: t('profile.address.zipcode'),
        value: userInfo.value.zipcode,
      },
    ]
  })

  /**
   * @constant userPersonalData [computable]
   * @returns Fields needed to show in personal profile
   */
  const userPersonalData = computed(() => {
    return [
      {
        title: t('profile.personal.email'),
        value: userInfo.value.email,
      },
      {
        title: t('profile.personal.phone'),
        value: userInfo.value.phone,
      },
    ]
  })

  /**
   * @constant userQuiz [mutable]
   * @type {Object}
   * @description Information about user quiz progress
   * @param {Number} progress - progress of unfinished quiz
   * @param {Boolean} done - indicates if user completed quiz
   * @param {Number} attempts - how many times user completed quiz in this month
   * @param {Number|undefined} lastAttempt - timestamp of last quiz attempt
   * @param {Number} maxAttempts - static number, currently locked by 3 attempts/mo
   */
  const userQuiz = ref({
    progress: 0,
    attempts: 0,
    lastAttempt: undefined,
    maxAttempts: 3,
  })

  /**
   * @constant quizLimitReached [computable]
   * @type {Boolean}
   * @returns true if user reached quiz monthly limit (3 by default)
   */
  const quizLimitReached = computed(() => {
    return userQuiz.value.attempts >= userQuiz.value.maxAttempts
  })

  /**
   * @constant quizUntouched [computable]
   * @type {Boolean}
   * @returns true if user haven't passed quiz once in this month (or ever)
   */
  const quizUntouched = computed(() => {
    return userQuiz.value.attempts == 0 && userQuiz.value.progress == 0
  })

  /**
   * @constant canRetakeQuiz [computable]
   * @type {Boolean}
   * @returns true if current quiz progress == 100 and user haven't reached monthly limit yet
   */
  const canRetakeQuiz = computed(() => {
    return !quizLimitReached.value && userQuiz.value.progress == 100
  })

  /**
   * @constant canContinueQuiz [computable]
   * @type {Boolean}
   * @returns true if current quiz progress between 0...100 and user haven't reached monthly limit yet
   */
  const canContinueQuiz = computed(() => {
    return userQuiz.value.progress > 0 && userQuiz.value.progress < 100
  })

  /**
   * @constant userSkinState [mutable]
   * @type {Object}
   * @description Information about user skin state based on Quiz responses
   * @param {String} title - title below diagrams, static value
   * @param {String} description - description that appears in tooltip
   * @param {Number} score - number 0...100 that shows related score param
   * @param {String|undefined} grade - lexical representation of score
   */
  const userSkinState = ref({
    hydration: {
      title: 'Hydration',
      description: 'Information about user skin state based on Quiz responses',
      score: 0,
      grade: undefined,
    },
    oxidative_stress: {
      title: 'Oxidative Stress',
      description: 'Information about user skin state based on Quiz responses',
      score: 0,
      grade: undefined,
    },
    skin_elasticity: {
      title: 'Elasticity',
      description: 'Information about user skin state based on Quiz responses',
      score: 0,
      grade: undefined,
    },
    skin_regeneration: {
      title: 'Regeneration',
      description: 'Information about user skin state based on Quiz responses',
      score: 0,
      grade: undefined,
    },
  })

  /**
   * @constant criterionStatus [computable]
   * @type {String}
   * @param {String} grade - lexical representation of score
   * @returns NaiveUI theme color based on passed param
   */
  const criterionStatus = grade => {
    if (grade == 'Bad') return 'error'
    else if (grade == 'Normal') return 'warning'
    else if (grade == 'Good') return 'success'
    else return 'default'
  }

  /**
   * @constant criterionProgress [computable]
   * @type {Number}
   * @param {String} grade - lexical representation of score
   * @returns Static number based on passed param
   */
  const criterionProgress = grade => {
    if (grade == 'Bad') return getRandomInt(11, 20)
    else if (grade == 'Normal') return getRandomInt(41, 60)
    else if (grade == 'Good') return getRandomInt(71, 95)
    else return 0
  }

  /**
   * @constant recomendation [mutable]
   * @type {String|null} - text generated by AI describing general recomendations about user skin
   * @default null
   */
  const recomendation = ref(null)

  /**
   * @constant recomendationNotReady [computable]
   * @type {Boolean}
   * @returns Boolean based on privded params from REST Api
   * @see {@link https://youtrack.musqogee.com/issue/LY-322}
   */
  const recomendationNotReady = computed(() => {
    return userInfo.value.profileId
           && userQuiz.value.progress == 100
           && !recomendation.value
  })

  /**
   * @constant userSkinConcerns [mutable]
   * @type {Array}
   * @description
   * Server can return many concerns but only few of them needs to be displayed.
   * In this constant listed only necessary concerns based on ID: 22,23,24,26,28,29
   * This concerns must be synced with Back-end devs
   * @param {Number} id - unique id returned from server, usefull for icons
   * @param {String} name - name of concern
   * @param {String} grade - lexical representation of concern
   * @param {Number} progress - computable client-side static value based on grade
   * @param {Boolean} aiBased - indicates if concern was detected with AI
   */
  const userSkinConcerns = ref([
    {
      id: 22,
      name: 'Acne',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
    {
      id: 23,
      name: 'Oily',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
    {
      id: 24,
      name: 'Wrinkles',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
    {
      id: 26,
      name: 'Radiance',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
    {
      id: 28,
      name: 'Redness',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
    {
      id: 29,
      name: 'Dryness',
      grade: undefined,
      progress: 0,
      aiBased: false,
    },
  ])

  /**
   * @constant ingredients [mutable]
   * @type {Array}
   * @description List of ingredients after user passed quiz
   */
  const ingredients = ref([])

  /**
   * @constant recommendedProducts [mutable]
   * @type {Array}
   * @description List of products recommended for user
   */
  const recommendedProducts = ref([])

  /**
   * @constant recommendedProductsEmpty [computable]
   * @type {Boolean}
   * @description returns true of length of recommended products == 0
   */
  const recommendedProductsEmpty = computed(() => {
    return recommendedProducts.value.length == 0
  })

  /**
   * @constant recommendedProductsIntro [mutable]
   * @type {String}
   * @description contains a small sequence to put above recommended products
   */
  const recommendedProductsIntro = ref('')

  /**
   * @constant responses [mutable]
   * @type {Object}
   * @description
   * This object contains current API response state for specific reqests
   * You can use that to show preloaders or placeholders in templates
   * @example
   * <div v-if="store.responses.getChatHistory">
   *   I am placeholder
   * </div>
   * <div v-else>
   *   Loaded content
   * </div>
   */
  const responses = ref({
    getUserProfile: false,
    getRecommendedProducts: false,
  })

  /**
   * @function saveUserInfo
   * @description Saves basic user data
   * @param {Object} data - object data received from API
   */
  function saveUserInfo(data) {
    Object.keys(userInfo.value).forEach(key => {
      if (data[key]) {
        userInfo.value[key] = data[key]
      } else if (data.personal_info[key]) {
        userInfo.value[key] = data.personal_info[key]
      }
    })
  }

  /**
   * @function saveQuizStats
   * @description Saves quiz progress and stats
   * @param {Object} data - object data received from API
   */
  function saveQuizStats(data) {
    userQuiz.value.progress = data.profile.progress
    userQuiz.value.attempts = data.profiles_count
    userQuiz.value.lastAttempt = new Date(data.profile.updated_at).getTime()
  }

  /**
   * @function saveUserSkinState
   * @description Saves information about user skin
   * @param {Object} data - object data received from API
   */
  function saveUserSkinState(data) {
    Object.keys(userSkinState.value).forEach(key => {
      userSkinState.value[key].grade = data.profile[`${key}_grade`]
      userSkinState.value[key].score = data.profile[`${key}_score`]
    })
  }

  /**
   * @function saveRecomendation
   * @description
   * Saves skin recomendations provided by Lycaste AI
   * Make sure this data is fully secure cause component renders it with v-html directive
   * @see {@link https://vuejs.org/api/built-in-directives.html#v-html}
   */
  function saveRecomendation(val) {
    if (val) {
      recomendation.value = val.replace(/\n/g, '<br />')
    }
  }

  /**
   * @function saveIngredients
   * @description Saves ingredients generated with Quiz
   * @param {Object} data - object data received from API
   */
  function saveIngredients(data) {
    ingredients.value = []
    if (data.components.length > 0) {
      for (let i = 0; i < data.components.length; i++) {
        ingredients.value.push({
          id: data.components[i].externalId,
          name: data.components[i].name
        })
      }
    }
  }

  /**
   * @function saveUserSkinConcerns
   * @description Saves user skin concerns detected with Quiz
   * @param {Object} data - object data received from API
   */
  function saveUserSkinConcerns(data) {
    if (data.concerns.length > 0) {
      // save Quiz concerns
      for (let i = 0; i < data.concerns.length; i++) {
        const item = data.concerns[i]
        const target = userSkinConcerns.value.filter(n => n.id == item.externalId)[0]
        if (target) {
          target.id = item.externalId
          target.name = item.name
          target.grade = item.pivot.grade
          target.progress = criterionProgress(item.pivot.grade)
        }
      }
      // replace Quiz concerns with Lycaste AI concerns
      if (Object.keys(data.ai_concerns).length > 0) {
        Object.keys(data.ai_concerns).forEach(key => {
          const target = userSkinConcerns.value.filter(n => n.id == key)[0]
          if (target) {
            target.grade = data.ai_concerns[key]
            target.aiBased = true
          }
        })
      }
    }
  }

  /**
   * @function saveRecommendedProducts
   * @description saves list of recommended products
   * @param {Array} data - list of products
   */
  function saveRecommendedProducts(data) {
    recommendedProducts.value = data
  }

  /**
   * @function saveRecommendedProducts
   * @description saves a smal seqence above user products
   * @param {String} data - seqence
   */
  function saveRecommendedProductsIntro(data) {
    recommendedProductsIntro.value = data
  }

  /**
   * @function getUserProfile
   * @description Function to get user profile
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will throw Promise reject if Axios catches some error in .then()
   * Will fire notification that user must login again and send to login page
   */
  function getUserProfile() {
    const notificationsStore = useNotificationsStore()
    const authStore = useAuthStore()
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_USER_API)
      if (JwtService.getToken()) {
        responses.value.getUserProfile = true
        ApiService.setHeader()
        ApiService.get(import.meta.env.VITE_USER_PROFILE)
          .then(({data}) => {
            if (data.success) {
              saveUserInfo(data)
              saveQuizStats(data)
              saveUserSkinState(data)
              saveRecomendation(data.profile.open_ai_summary)
              saveUserSkinConcerns(data)
              saveIngredients(data)
            } else {
              notificationsStore.pushNotification({
                message: data.message,
                type: 'error'
              })
            }
            resolve({data})
          })
          .catch(err => {
            notificationsStore.pushNotification({
              message: err.message,
              type: 'error'
            })
            console.error(err)
            reject(err)
          })
          .finally(() => {
            setTimeout(() => { responses.value.getUserProfile = false }, 250)
          })
      } else {
        authStore.logOut()
      }
    })
  }

  /**
   * @function getRecommendedProducts
   * @description Function to get products recommended for user
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will throw Promise reject if Axios catches some error in .then()
   * Will fire notification that user must login again and send to login page
   */
  function getRecommendedProducts() {
    const notificationsStore = useNotificationsStore()
    const authStore = useAuthStore()
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_CHAT_API)
      if (JwtService.getToken()) {
        responses.value.getRecommendedProducts = true
        ApiService.setHeader()
        ApiService.get(import.meta.env.VITE_RECOMMENDED_PRODUCTS)
          .then(({data}) => {
            if (data.success) {
              saveRecommendedProductsIntro(data.recommendations.intro)
              saveRecommendedProducts(data.recommendations.products)
            } else {
              notificationsStore.pushNotification({
                message: data.message,
                type: 'error'
              })
            }
            resolve({data})
          })
          .catch(err => {
            notificationsStore.pushNotification({
              message: err.message,
              type: 'error'
            })
            console.error(err)
            reject(err)
          })
          .finally(() => {
            setTimeout(() => { responses.value.getRecommendedProducts = false }, 250)
          })
      } else {
        authStore.logOut()
      }
    })
  }

  return {
    userInfo,
    profilePending,
    productsPending,
    userInitials,
    userAddress,
    userPersonalData,
    userQuiz,
    canRetakeQuiz,
    canContinueQuiz,
    quizLimitReached,
    quizUntouched,
    userSkinState,
    criterionStatus,
    criterionProgress,
    recomendation,
    recomendationNotReady,
    userSkinConcerns,
    ingredients,
    recommendedProducts,
    recommendedProductsEmpty,
    recommendedProductsIntro,
    responses,

    getUserProfile,
    getRecommendedProducts,
  }
})
